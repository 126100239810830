import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../nav/Loading";
import { UserContext } from "./UserProvider";
export var PrivateRoute = function (_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var _b = useContext(UserContext), user = _b.user, loading = _b.loading;
    if (loading) {
        return _jsx(Loading, {});
    }
    if (user === null) {
        navigate("/login");
    }
    return children;
};
