var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./Dragger";
var style = {
    height: "100%",
    width: "100%",
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "normal",
    float: "left",
};
var selectBackgroundColor = function (isActive) {
    return isActive ? "LightGray" : "WhiteSmoke";
};
export var Dropper = function (_a) {
    var name = _a.name, children = _a.children;
    var _b = __read(useDrop(function () { return ({
        accept: ItemTypes.BOX,
        drop: function () { return ({
            name: name,
        }); },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }); },
    }); }, []), 2), _c = _b[0], canDrop = _c.canDrop, isOver = _c.isOver, drop = _b[1];
    var isActive = canDrop && isOver;
    var backgroundColor = selectBackgroundColor(isActive);
    return (_jsx("div", { ref: drop, style: __assign(__assign({}, style), { backgroundColor: backgroundColor }), children: children }));
};
