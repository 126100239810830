import { createTheme } from "@mui/material";
export var theme = createTheme({
    palette: {
        primary: {
            main: "#34416b",
        },
        secondary: {
            main: "#fac02e",
        },
    },
});
