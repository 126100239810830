var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateIntraSquadMatch, useRetrieveTeam } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { useNumericParams } from "../utils/useNumericParams";
import { IntraSquadMatchForm } from "./IntraSquadMatchForm";
export var CreateIntraSquadMatch = function () {
    var teamId = useNumericParams().id;
    var _a = useRetrieveTeam({ id: teamId }), useRetrieveTeamIsLoading = _a.isLoading, useRetrieveTeamError = _a.error, team = _a.data;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(undefined), 2), mutationError = _c[0], setError = _c[1];
    var navigate = useNavigate();
    var mutation = useCreateIntraSquadMatch({
        team: team,
        onMutate: function () {
            setLoading(true);
        },
        onSuccess: function (data) {
            var intraSquadMatch = data.data;
            return navigate("/intra-squad-matches/".concat(intraSquadMatch.id, "/"));
        },
        onError: function (error) {
            setLoading(false);
            setError(error);
        },
    });
    var onSubmit = function (intraSquadMatch) {
        mutation.mutate(intraSquadMatch);
    };
    if (loading || useRetrieveTeamIsLoading) {
        return _jsx(Loading, {});
    }
    if (useRetrieveTeamError || !team) {
        return _jsx(ErrorView, { error: useRetrieveTeamError });
    }
    return (_jsxs(React.Fragment, { children: [mutationError && (_jsxs(Alert, { variant: "outlined", severity: "error", children: ["Failed to create team \u2014 ", mutationError.message, "!"] })), _jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Create Intra-Squad Match for ", team.name] }) }), _jsx(IntraSquadMatchForm, { team: team, onSubmit: onSubmit })] }));
};
