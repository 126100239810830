var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
var Alert = React.forwardRef(function Alert(props, ref) {
    return _jsx(MuiAlert, __assign({ elevation: 6, ref: ref, variant: "filled" }, props));
});
var ErrorSnackbar = function (_a) {
    var open = _a.open, _b = _a.onClose, onClose = _b === void 0 ? function () { } : _b, message = _a.message;
    return (_jsx(Snackbar, { open: open, autoHideDuration: 6000, onClose: onClose, children: _jsx(Alert, { onClose: onClose, severity: "error", sx: { width: "100%" }, children: message }) }));
};
export default ErrorSnackbar;
