var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var Result;
(function (Result) {
    Result[Result["Win"] = 0] = "Win";
    Result[Result["Draw"] = 1] = "Draw";
    Result[Result["Loss"] = 2] = "Loss";
})(Result || (Result = {}));
export var getPlayerResult = function (playerId, intraSquadMatch) {
    var result = intraSquadMatch.intraSquadMatchResult;
    if (!result)
        return undefined;
    var isDraw = result.teamAScore === result.teamBScore;
    if (isDraw)
        return Result.Draw;
    var winningTeamPlayers = result.teamAScore > result.teamBScore
        ? intraSquadMatch.teamAPlayers
        : intraSquadMatch.teamBPlayers;
    return (winningTeamPlayers === null || winningTeamPlayers === void 0 ? void 0 : winningTeamPlayers.includes(playerId)) ? Result.Win : Result.Loss;
};
export var aggregatePlayerIntraSquadMatchStats = function (intraSquadMatches) {
    return intraSquadMatches.reduce(function (statsMap, match) {
        var players = __spreadArray(__spreadArray([], __read((match.teamAPlayers || [])), false), __read((match.teamBPlayers || [])), false);
        players.forEach(function (playerId) {
            var _a;
            if (playerId === undefined)
                return;
            // Calculate match-specific stats
            var resultStats = calculatePlayerResultStats(playerId, match);
            var playerStats = (_a = match.playerIntraSquadMatchStats) === null || _a === void 0 ? void 0 : _a.find(function (stat) { return stat.playerId === playerId; });
            // Create new stats or update existing ones
            var existingStats = statsMap[playerId] || {
                matchesPlayed: 0,
                goalsScored: 0,
                yellowCards: 0,
                redCards: 0,
                wins: 0,
                losses: 0,
                draws: 0,
            };
            statsMap[playerId] = {
                matchesPlayed: existingStats.matchesPlayed + 1,
                goalsScored: existingStats.goalsScored + ((playerStats === null || playerStats === void 0 ? void 0 : playerStats.goalsScored) || 0),
                yellowCards: existingStats.yellowCards + ((playerStats === null || playerStats === void 0 ? void 0 : playerStats.yellowCards) || 0),
                redCards: existingStats.redCards + ((playerStats === null || playerStats === void 0 ? void 0 : playerStats.redCards) || 0),
                wins: existingStats.wins + resultStats.wins,
                losses: existingStats.losses + resultStats.losses,
                draws: existingStats.draws + resultStats.draws,
            };
        });
        return statsMap;
    }, {});
};
export var calculatePlayerResultStats = function (playerId, intraSquadMatch) {
    var result = getPlayerResult(playerId, intraSquadMatch);
    return {
        wins: result === Result.Win ? 1 : 0,
        draws: result === Result.Draw ? 1 : 0,
        losses: result === Result.Loss ? 1 : 0,
    };
};
export var findPlayerIntraSquadMatchStatByPlayerId = function (stats) {
    return function (id) {
        return stats.find(function (stat) { return stat.playerId === id; });
    };
};
