var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Button, Card, CardContent, Divider, Paper, Typography, } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteUser, useRetrievePlayerByUserId } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { playerDisplayName } from "../players/PlayerDisplayNameLink";
import { DeleteDialog } from "../utils/DeleteDialog";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { UserContext } from "./UserProvider";
var MyAccount = function () {
    var _a = useContext(UserContext), user = _a.user, loading = _a.loading, setUser = _a.setUser, setLoading = _a.setLoading;
    var navigate = useNavigate();
    var _b = useRetrievePlayerByUserId({ userId: user === null || user === void 0 ? void 0 : user.pk }), player = _b.data, isPlayerLoading = _b.isLoading, isPlayerError = _b.isError;
    var deleteUserMutation = useDeleteUser({
        onSuccess: function () {
            setUser(null);
            setLoading(false);
            navigate("/");
        },
        onError: function (error) {
            console.error(error);
        },
        onMutate: function () { },
    });
    var _c = __read(React.useState(false), 2), openDeleteDialog = _c[0], setOpenDeleteDialog = _c[1];
    if (loading || isPlayerLoading) {
        return _jsx(Loading, {});
    }
    if (!user || !player || isPlayerError) {
        return _jsx(ErrorView, { error: "Error retrieving user details" });
    }
    var formatDate = function (dateString) {
        var date = new Date(dateString);
        return new Intl.DateTimeFormat("en-US", {
            month: "long",
            year: "numeric",
        }).format(date);
    };
    var handleDeleteClick = function () {
        setOpenDeleteDialog(true);
    };
    var displayName = playerDisplayName(player);
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: deleteUserMutation.isError, message: "Failed to delete user!" }), _jsxs(Card, { sx: { maxWidth: 400, margin: "auto", mt: 4 }, children: [_jsxs(CardContent, { children: [_jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", children: [_jsx(Avatar, { alt: displayName, src: user === null || user === void 0 ? void 0 : user.picture, sx: { width: 80, height: 80, mb: 2 } }), _jsx(Typography, { variant: "h6", children: displayName }), _jsx(Typography, { variant: "body2", color: "text.secondary", children: user.email }), _jsxs(Typography, { variant: "body2", color: "text.secondary", sx: { mt: 1 }, children: ["Member since: ", formatDate(player.createdAt)] })] }), _jsx(Divider, { sx: { my: 3 } }), _jsxs(Paper, { sx: { p: 2, backgroundColor: "#ffe6e6" }, children: [_jsx(Typography, { variant: "body1", color: "error", sx: { fontWeight: "bold", mb: 1 }, children: "Danger Zone" }), _jsx(Typography, { variant: "body2", color: "text.secondary", sx: { mb: 2 }, children: "Deleting your account is permanent and cannot be undone. Please proceed with caution." }), _jsx(Button, { variant: "contained", color: "error", onClick: handleDeleteClick, fullWidth: true, sx: { fontWeight: "bold" }, children: "Delete Account" })] })] }), _jsx(DeleteDialog, { open: openDeleteDialog, title: "Delete your account?", onCancel: function () {
                            setOpenDeleteDialog(false);
                        }, onConfirm: function () {
                            deleteUserMutation.mutate();
                        } })] })] }));
};
export default MyAccount;
