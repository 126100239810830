import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRetrieveIntraSquadMatch, useRetrieveTeam, useUpdateIntraSquadMatch, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { useNumericParams } from "../utils/useNumericParams";
import { IntraSquadMatchForm } from "./IntraSquadMatchForm";
export var UpdateIntraSquadMatch = function () {
    var id = useNumericParams().id;
    var _a = useRetrieveIntraSquadMatch({ id: id }), useRetrieveIntraSquadMatchIsLoading = _a.isLoading, useRetrieveIntraSquadMatchError = _a.error, intraSquadMatch = _a.data;
    var _b = useRetrieveTeam({ id: intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team }), team = _b.data, teamError = _b.error, teamIsLoading = _b.isLoading;
    var navigate = useNavigate();
    var mutation = useUpdateIntraSquadMatch({
        id: id,
        team: team,
        onMutate: function () { },
        onSuccess: function () {
            return navigate("/intra-squad-matches/".concat(id, "/"));
        },
        onError: console.error,
    });
    var onSubmit = function (intraSquadMatch) {
        mutation.mutate(intraSquadMatch);
    };
    if (useRetrieveIntraSquadMatchIsLoading || teamIsLoading) {
        return _jsx(Loading, {});
    }
    if (useRetrieveIntraSquadMatchError ||
        !(intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team) ||
        !team ||
        teamError) {
        return _jsx(ErrorView, { error: useRetrieveIntraSquadMatchError });
    }
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: mutation.isError, message: "Failed to update Intra-Squad Match. Please try again." }), _jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Update Intra-Squad Match for ", team.name] }) }), _jsx(IntraSquadMatchForm, { intraSquadMatch: intraSquadMatch, team: team, onSubmit: onSubmit })] }));
};
