var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleButton } from "./GoogleButton";
import "./LandingPage.css"; // You can style your landing page in this CSS file
import { UserContext } from "./auth/UserProvider";
var Landing = function () {
    var setUser = useContext(UserContext).setUser;
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var navigate = useNavigate();
    var login = useGoogleLogin({
        onSuccess: function (tokenResponse) {
            console.log(tokenResponse);
            setLoading(true);
            axios
                .post("/dj-rest-auth/google/", {
                accessToken: tokenResponse.access_token,
            })
                .then(function (response) {
                var user = response.data.user;
                setUser(user);
                setLoading(false);
                navigate("/");
            })
                .catch(function (error) {
                console.error(error);
                console.error("YO!");
                document.cookie =
                    "log-soccer-auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=None;";
                document.cookie =
                    "log-soccer-refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=None;";
            });
        },
        onError: function (error) {
            console.log("Error retrieving Google credentials ".concat(error));
        },
    });
    return (_jsx(React.Fragment, { children: _jsx(Container, { component: "main", maxWidth: "xs", children: _jsx(Box, { sx: {
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    opacity: "100%",
                }, children: loading ? (_jsx(CircularProgress, {})) : (_jsxs(React.Fragment, { children: [_jsx(Box, { className: "landing-page", sx: { padding: "50px", textAlign: "center" }, children: _jsxs(Box, { className: "header", sx: { marginBottom: "20px" }, children: [_jsx(Typography, { variant: "h1", sx: { fontSize: "36px", marginBottom: "10px" }, children: "Welcome to log.soccer" }), _jsx(Typography, { variant: "subtitle1", sx: { fontSize: "18px", marginBottom: "5px" }, children: "Data-Driven Goals for Soccer Souls" }), _jsx(Typography, { variant: "subtitle1", sx: { fontSize: "18px", marginBottom: "5px" }, children: "Amp Up Your Team's Game!" })] }) }), _jsx(Avatar, { sx: { m: 1, bgcolor: "main" }, children: _jsx(LockOutlinedIcon, {}) }), _jsx(Typography, { component: "h1", variant: "h5" }), _jsx(Box, { sx: { mt: 1 }, children: _jsx(GoogleButton, { onClick: function () { return login(); }, text: "Signup with Google" }) })] })) }) }) }));
};
export default Landing;
