var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Paper, styled } from "@mui/material";
import { useDrag } from "react-dnd";
var style = {
    border: "1px dashed gray",
    padding: "0.5rem 1rem",
    float: "left",
};
export var ItemTypes = {
    BOX: "box",
};
export var Item = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({ backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff" }, theme.typography.body2), { padding: theme.spacing(1), textAlign: "center", width: "90%", color: theme.palette.text.secondary }));
});
export var Dragger = function (_a) {
    var label = _a.label, item = _a.item, onDrop = _a.onDrop, deps = _a.deps;
    var _b = __read(useDrag(function () { return ({
        type: ItemTypes.BOX,
        item: { item: item },
        end: function (item, monitor) {
            onDrop(item.item, monitor);
        },
        collect: function (monitor) { return ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }); },
    }); }, __spreadArray([label], __read(deps), false)), 2), opacity = _b[0].opacity, drag = _b[1];
    return (_jsx(Item, { ref: drag, style: __assign(__assign({}, style), { opacity: opacity }), children: label }));
};
