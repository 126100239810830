var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography, } from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import { playerDisplayName, PlayerDisplayNameLink, } from "../players/PlayerDisplayNameLink";
import { aggregatePlayerIntraSquadMatchStats } from "./utils";
var singlePlayerViewHeadCells = [
    { id: "matchesPlayed", label: "Matches Played" },
    { id: "wins", label: "Wins" },
    { id: "draws", label: "Draws" },
    { id: "losses", label: "Losses" },
    { id: "goalsScored", label: "Goals Scored" },
];
var headCells = __spreadArray([
    { id: "player", label: "Player" }
], __read(singlePlayerViewHeadCells), false);
export var IntraSquadMatchStatsTable = function (_a) {
    var intraSquadMatches = _a.intraSquadMatches, players = _a.players, _b = _a.singlePlayerView, singlePlayerView = _b === void 0 ? false : _b;
    var statsMap = useMemo(function () { return aggregatePlayerIntraSquadMatchStats(intraSquadMatches); }, [intraSquadMatches]);
    var _c = __read(useState("asc"), 2), order = _c[0], setOrder = _c[1];
    var _d = __read(useState(singlePlayerView ? "matchesPlayer" : "player"), 2), orderBy = _d[0], setOrderBy = _d[1];
    var handleRequestSort = function (property) {
        if (!singlePlayerView) {
            var isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        }
    };
    var sortedPlayers = useMemo(function () {
        if (singlePlayerView) {
            return players;
        }
        return __spreadArray([], __read(players), false).sort(function (a, b) {
            if (orderBy === "player") {
                var nameA = "".concat(playerDisplayName(a)).toLowerCase();
                var nameB = "".concat(playerDisplayName(b)).toLowerCase();
                return order === "asc"
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            }
            var aStats = statsMap[a.id] || {};
            var bStats = statsMap[b.id] || {};
            var aValue = aStats[orderBy] || 0;
            var bValue = bStats[orderBy] || 0;
            return order === "asc" ? aValue - bValue : bValue - aValue;
        });
    }, [players, statsMap, order, orderBy]);
    if (players.length === 0) {
        return (_jsx(Typography, { variant: "body1", align: "center", children: "No players available" }));
    }
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: (singlePlayerView ? singlePlayerViewHeadCells : headCells).map(function (headCell) { return (_jsx(TableCell, { children: singlePlayerView ? (_jsx(Fragment, { children: headCell.label })) : (_jsx(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : "asc", onClick: function () { return handleRequestSort(headCell.id); }, children: headCell.label })) }, headCell.id)); }) }) }), _jsx(TableBody, { children: sortedPlayers.map(function (Player, index) {
                        var aggregatedStats = statsMap[Player.id];
                        return (_jsxs(TableRow, { sx: { backgroundColor: index % 2 ? "white" : "whitesmoke" }, children: [!singlePlayerView && (_jsx(TableCell, { children: _jsx(PlayerDisplayNameLink, { player: Player }) })), _jsx(TableCell, { children: (aggregatedStats === null || aggregatedStats === void 0 ? void 0 : aggregatedStats.matchesPlayed) || 0 }), _jsx(TableCell, { children: (aggregatedStats === null || aggregatedStats === void 0 ? void 0 : aggregatedStats.wins) || 0 }), _jsx(TableCell, { children: (aggregatedStats === null || aggregatedStats === void 0 ? void 0 : aggregatedStats.draws) || 0 }), _jsx(TableCell, { children: (aggregatedStats === null || aggregatedStats === void 0 ? void 0 : aggregatedStats.losses) || 0 }), _jsx(TableCell, { children: (aggregatedStats === null || aggregatedStats === void 0 ? void 0 : aggregatedStats.goalsScored) || 0 })] }, Player.id));
                    }) })] }) }));
};
