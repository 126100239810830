var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useRetrievePlayersByTeamId, useRetrieveTeamPreferences, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { TeamChooser } from "./TeamChooser";
export var IntraSquadMatchForm = function (_a) {
    var team = _a.team, onSubmit = _a.onSubmit, intraSquadMatch = _a.intraSquadMatch;
    var useFormProps = useForm({
        defaultValues: {
            kickOff: intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.kickOff,
            teamAPlayers: (intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.teamAPlayers) || [],
            teamBPlayers: (intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.teamBPlayers) || [],
            team: team.id,
        },
    });
    var control = useFormProps.control, handleSubmit = useFormProps.handleSubmit, errors = useFormProps.formState.errors;
    var _b = useRetrievePlayersByTeamId({ teamId: team.id }), players = _b.data, playersError = _b.error, playersIsLoading = _b.isLoading;
    var _c = useRetrieveTeamPreferences({ teamId: team.id }), useRetrieveTeamPreferenceIsLoading = _c.isLoading, useRetrieveTeamPreferenceError = _c.error, teamPreference = _c.data;
    if (useRetrieveTeamPreferenceIsLoading || playersIsLoading) {
        return _jsx(Loading, {});
    }
    if (!team ||
        useRetrieveTeamPreferenceError ||
        !teamPreference ||
        playersError ||
        !players) {
        return _jsx(ErrorView, { error: useRetrieveTeamPreferenceError });
    }
    return (_jsx(FormProvider, __assign({}, useFormProps, { children: _jsxs(Box, { component: "form", onSubmit: handleSubmit(onSubmit), noValidate: true, sx: { mt: 1 }, children: [_jsx(Controller, { name: "kickOff", control: control, rules: { required: "Kick-Off is required" }, render: function (_a) {
                        var field = _a.field;
                        return (_jsx(DateTimePicker, __assign({ label: "Kick-Off" }, field, { value: field.value ? dayjs(field.value) : null, onChange: function (newValue) { return field.onChange(newValue); }, sx: { width: "100%" }, slotProps: {
                                textField: {
                                    error: !!errors.kickOff,
                                    helperText: errors.kickOff ? errors.kickOff.message : "",
                                },
                            } })));
                    } }), _jsx(Divider, { sx: {
                        margin: "20px",
                    } }), _jsx(TeamChooser, { players: players, teamPreference: teamPreference }), _jsx(Button, { type: "submit", fullWidth: true, variant: "contained", sx: { mt: 3, mb: 2 }, children: intraSquadMatch === undefined ? "Create" : "Update" })] }) })));
};
