var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRetrieveTeam, useRetrieveTeamPreferences, useUpdateTeamPreference, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { useNumericParams } from "../utils/useNumericParams";
export var UpdateTeamPreference = function () {
    var _a = useForm(), control = _a.control, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(undefined), 2), error = _c[0], setError = _c[1];
    var navigate = useNavigate();
    var teamId = useNumericParams().id;
    var _d = useRetrieveTeam({ id: teamId }), isTeamLoading = _d.isLoading, teamError = _d.error, team = _d.data;
    var _e = useRetrieveTeamPreferences({ teamId: teamId }), teamPreferencesIsLoading = _e.isLoading, teamPreferencesError = _e.error, teamPreference = _e.data;
    var mutation = useUpdateTeamPreference({
        teamId: teamId,
        onMutate: function () {
            setLoading(true);
        },
        onSuccess: function () {
            return navigate("/teams/".concat(team === null || team === void 0 ? void 0 : team.id, "/"));
        },
        onError: function (error) { return setError(error); },
    });
    if (isTeamLoading || teamPreferencesIsLoading || loading) {
        return _jsx(Loading, {});
    }
    if (teamPreferencesError || error || teamError) {
        return _jsx(ErrorView, { error: teamPreferencesError });
    }
    var onSubmit = function (data) {
        mutation.mutate(data);
    };
    if (!team) {
        return (_jsx(Typography, { variant: "h6", children: "Could not retrieve team information!" }));
    }
    if (!teamPreference) {
        return (_jsxs(Typography, { variant: "h6", children: ["Could not retrieve ", team.name, " preferences!"] }));
    }
    return (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Update ", team === null || team === void 0 ? void 0 : team.name, " Preferences"] }) }), _jsxs(Box, { component: "form", role: "form", onSubmit: handleSubmit(onSubmit), noValidate: true, sx: { mt: 1 }, children: [_jsx(Controller, { name: "teamAName", control: control, defaultValue: teamPreference.teamAName, rules: { required: "Team A Display Name is required" }, render: function (_a) {
                            var field = _a.field;
                            return (_jsx(TextField, __assign({}, field, { margin: "normal", required: true, fullWidth: true, id: "teamAName", label: "Team A Display Name", autoComplete: "teamAName", autoFocus: true, error: !!errors.teamAName, helperText: errors.teamAName ? errors.teamAName.message : "" })));
                        } }), _jsx(Controller, { name: "teamBName", control: control, defaultValue: teamPreference.teamBName, rules: { required: "Team B Display Name is required" }, render: function (_a) {
                            var field = _a.field;
                            return (_jsx(TextField, __assign({}, field, { margin: "normal", required: true, fullWidth: true, id: "teamBName", label: "Team B Display Name", autoComplete: "teamBName", error: !!errors.teamBName, helperText: errors.teamBName ? errors.teamBName.message : "" })));
                        } }), _jsx(Button, { type: "submit", fullWidth: true, variant: "contained", sx: { mt: 3, mb: 2 }, children: "Update" })] })] }));
};
