var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRetrievePlayer, useUpdatePlayer } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { useNumericParams } from "../utils/useNumericParams";
import { PlayerDisplayNameLink } from "./PlayerDisplayNameLink";
import { PlayerForm } from "./PlayerForm";
export var UpdatePlayerForm = function () {
    var id = useNumericParams().id;
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var navigate = useNavigate();
    var updatePlayerMutation = useUpdatePlayer({
        id: id,
        onMutate: function () {
            setLoading(true);
        },
        onSuccess: function (data) {
            navigate("/players/".concat(id, "/"));
            return data.data;
        },
        onError: function () {
            setLoading(false);
        },
    });
    var onSubmit = function (player) {
        updatePlayerMutation.mutate(player);
    };
    var _b = useRetrievePlayer({ id: id }), useRetrievePlayerIsLoading = _b.isLoading, useRetrievePlayerError = _b.error, player = _b.data;
    if (loading || useRetrievePlayerIsLoading || !player) {
        return _jsx(Loading, {});
    }
    if (useRetrievePlayerError) {
        return _jsx(ErrorView, { error: useRetrievePlayerError });
    }
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: !!updatePlayerMutation.isError, message: "Failed to update player!" }), _jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Update ", _jsx(PlayerDisplayNameLink, { player: player })] }) }), _jsx(PlayerForm, { player: player, onSubmit: onSubmit, onCancel: function () {
                    navigate(-1);
                } })] }));
};
