var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleButton } from "../GoogleButton";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { UserContext } from "./UserProvider";
var Login = function () {
    var _a = useContext(UserContext), setUser = _a.setUser, setUserLoading = _a.setLoading;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(null), 2), error = _c[0], setError = _c[1];
    var navigate = useNavigate();
    var login = useGoogleLogin({
        onSuccess: function (tokenResponse) {
            setLoading(true);
            axios
                .post("/dj-rest-auth/google/", {
                access_token: tokenResponse.access_token,
            })
                .then(function (response) {
                var user = response.data.user;
                setLoading(false);
                setUser(user);
                setUserLoading(false);
                navigate("/");
            })
                .catch(function (error) {
                setLoading(false);
                console.error("Login failed:", error);
                setError("Login failed. Please try again.");
            });
        },
    });
    var handleCloseSnackbar = function () {
        setError(null);
    };
    return (_jsx(Container, { component: "main", maxWidth: "xs", children: _jsxs(Box, { sx: {
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }, children: [loading ? (_jsx(CircularProgress, { role: "progressbar" })) : (_jsxs(React.Fragment, { children: [_jsx(Avatar, { sx: { m: 1, bgcolor: "main" }, children: _jsx(LockOutlinedIcon, {}) }), _jsx(Typography, { role: "heading", component: "h1", variant: "h5", children: "Login" }), _jsx(Box, { sx: { mt: 1 }, children: _jsx(GoogleButton, { onClick: function () { return login(); }, text: "Login with Google" }) })] })), _jsx(ErrorSnackbar, { open: !!error, onClose: handleCloseSnackbar, message: error || "" })] }) }));
};
export default Login;
