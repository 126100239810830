import { useParams } from "react-router-dom";
/**
 * Custom hook to retrieve and validate numeric URL parameters.
 * @returns An object with validated numeric parameters, mimicking useParams syntax.
 */
export var useNumericParams = function () {
    var params = useParams();
    var numericParams = {};
    for (var key in params) {
        var value = parseInt(params[key], 10);
        if (isNaN(value) || value <= 0 || value > Number.MAX_SAFE_INTEGER) {
            throw new Error("Invalid or missing numeric parameter: ".concat(key));
        }
        // Cast `key` to `unknown` first, then to `T`
        numericParams[key] = value;
    }
    return numericParams;
};
