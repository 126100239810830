var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Slider, TextField, Typography, } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { countries } from "./countries";
var POSITION_CHOICES = [
    { value: "GK", label: "GK" },
    { value: "LB", label: "LB" },
    { value: "LWB", label: "LWB" },
    { value: "RB", label: "RB" },
    { value: "RWB", label: "RWB" },
    { value: "LCB", label: "LCB" },
    { value: "RCB", label: "RCB" },
    { value: "CB", label: "CB" },
    { value: "SW", label: "SW" },
    { value: "CDM", label: "CDM" },
    { value: "CM", label: "CM" },
    { value: "LCM", label: "LCM" },
    { value: "RCM", label: "RCM" },
    { value: "LM", label: "LM" },
    { value: "RM", label: "RM" },
    { value: "CAM", label: "CAM" },
    { value: "LW", label: "LW" },
    { value: "RW", label: "RW" },
    { value: "CF", label: "CF" },
    { value: "ST", label: "ST" },
];
var FEET_CHOICES = [
    { value: "Left", label: "Left" },
    { value: "Right", label: "Right" },
];
export var PlayerForm = function (_a) {
    var onSubmit = _a.onSubmit, player = _a.player, onCancel = _a.onCancel;
    var _b = useForm({
        defaultValues: {
            nationality: (player === null || player === void 0 ? void 0 : player.nationality) || "US",
            dob: player === null || player === void 0 ? void 0 : player.dob,
            heading: (player === null || player === void 0 ? void 0 : player.heading) || 50,
            passing: (player === null || player === void 0 ? void 0 : player.passing) || 50,
            defending: (player === null || player === void 0 ? void 0 : player.defending) || 50,
            positioning: (player === null || player === void 0 ? void 0 : player.positioning) || 50,
            finishing: (player === null || player === void 0 ? void 0 : player.finishing) || 50,
            speed: (player === null || player === void 0 ? void 0 : player.speed) || 50,
            fitness: (player === null || player === void 0 ? void 0 : player.fitness) || 50,
            keeping: (player === null || player === void 0 ? void 0 : player.keeping) || 50,
            dribbling: (player === null || player === void 0 ? void 0 : player.dribbling) || 50,
            shooting: (player === null || player === void 0 ? void 0 : player.shooting) || 50,
            tackling: (player === null || player === void 0 ? void 0 : player.tackling) || 50,
            crossing: (player === null || player === void 0 ? void 0 : player.crossing) || 50,
            leadership: (player === null || player === void 0 ? void 0 : player.leadership) || 50,
            communication: (player === null || player === void 0 ? void 0 : player.communication) || 50,
            bio: (player === null || player === void 0 ? void 0 : player.bio) || undefined,
            weight: (player === null || player === void 0 ? void 0 : player.weight) || undefined,
            heightFeet: (player === null || player === void 0 ? void 0 : player.heightFeet) || undefined,
            heightInch: (player === null || player === void 0 ? void 0 : player.heightInch) || undefined,
            foot: (player === null || player === void 0 ? void 0 : player.foot) || "Right",
            positionOne: (player === null || player === void 0 ? void 0 : player.positionOne) || "GK",
            positionTwo: (player === null || player === void 0 ? void 0 : player.positionTwo) || "GK",
        },
    }), control = _b.control, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    return (_jsxs(Box, { component: "form", role: "form", onSubmit: handleSubmit(function (data) {
            onSubmit(data);
        }), noValidate: true, sx: { mt: 1 }, children: [_jsx(Divider, { style: { margin: "15px 0" } }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { id: "country-select-label", children: "Nationality" }), _jsx(Controller, { name: "nationality", control: control, render: function (_a) {
                            var field = _a.field;
                            return (_jsx(Select, __assign({}, field, { labelId: "country-select-label", label: "Nationality", children: countries.map(function (country) { return (_jsx(MenuItem, { value: country.code, children: country.name }, country.code)); }) })));
                        } })] }), _jsx(Controller, { name: "bio", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, label: "Bio", autoComplete: "bio", multiline: true, rows: 3 })));
                } }), _jsx(Controller, { name: "dob", control: control, rules: {
                    validate: function (value) {
                        if (!value)
                            return true;
                        var selectedDate = new Date(value);
                        var today = new Date();
                        if (selectedDate > today) {
                            return "Date of birth cannot be in the future";
                        }
                        return true;
                    },
                }, render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, label: "Date of Birth", type: "date", InputLabelProps: {
                            shrink: true,
                        }, error: !!errors.dob, helperText: (_b = errors.dob) === null || _b === void 0 ? void 0 : _b.message })));
                } }), _jsx(Controller, { name: "weight", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, label: "Weight (kg)", type: "number" })));
                } }), _jsx(Controller, { name: "heightFeet", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, label: "Height (feet)", type: "number" })));
                } }), _jsx(Controller, { name: "heightInch", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, label: "Height (inches)", type: "number" })));
                } }), _jsx(Typography, { variant: "h6", style: { marginTop: "20px" }, children: "Positioning" }), _jsx(Divider, { style: { margin: "15px 0" } }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { "aria-label": "positionOne", children: "Primary Position" }), _jsx(Controller, { name: "positionOne", control: control, render: function (_a) {
                            var field = _a.field;
                            return (_jsx(Select, __assign({}, field, { label: "Primary Position", children: POSITION_CHOICES.map(function (position) { return (_jsx(MenuItem, { value: position.value, children: position.label }, position.value)); }) })));
                        } })] }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { children: "Secondary Position" }), _jsx(Controller, { name: "positionTwo", control: control, render: function (_a) {
                            var field = _a.field;
                            return (_jsx(Select, __assign({}, field, { label: "Secondary Position", children: POSITION_CHOICES.map(function (position) { return (_jsx(MenuItem, { value: position.value, children: position.label }, position.value)); }) })));
                        } })] }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { children: "Preferred Foot" }), _jsx(Controller, { name: "foot", control: control, render: function (_a) {
                            var field = _a.field;
                            return (_jsx(Select, __assign({}, field, { label: "Preferred Foot", children: FEET_CHOICES.map(function (foot) { return (_jsx(MenuItem, { value: foot.value, children: foot.label }, foot.value)); }) })));
                        } })] }), _jsx(Typography, { variant: "h6", style: { marginTop: "20px" }, children: "Physical Attributes" }), _jsx(Divider, { style: { margin: "15px 0" } }), _jsx(Controller, { name: "speed", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Speed" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "fitness", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Fitness" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "keeping", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Goalkeeping" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Typography, { variant: "h6", style: { marginTop: "20px" }, children: "Technical Attributes" }), _jsx(Divider, { style: { margin: "15px 0" } }), _jsx(Controller, { name: "passing", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Passing" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "finishing", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Finishing" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "defending", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Defending" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "positioning", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Positioning" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "heading", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Heading" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "dribbling", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Dribbling" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "shooting", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Shooting" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "tackling", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Tackling" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "crossing", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Crossing" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Typography, { variant: "h6", style: { marginTop: "20px" }, children: "Leadership Attributes" }), _jsx(Divider, { style: { margin: "15px 0" } }), _jsx(Controller, { name: "communication", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Communication" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsx(Controller, { name: "leadership", control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Leadership" }), _jsx(Slider, __assign({}, field, { min: 0, max: 100, valueLabelDisplay: "auto" }))] }));
                } }), _jsxs(Box, { sx: { display: "flex", justifyContent: "center", gap: 2, mt: 3, mb: 2 }, children: [_jsx(Button, { type: "submit", variant: "contained", color: "primary", size: "large", sx: { minWidth: 120 }, children: player === undefined ? "Create" : "Update" }), _jsx(Button, { variant: "outlined", color: "secondary", size: "large", sx: { minWidth: 120 }, onClick: onCancel, children: "Cancel" })] })] }));
};
