var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var filterOutPlayerById = function (players, player) {
    return players.filter(function (p) { return p.id !== player.id; });
};
export var onDrop = function (item, monitor, bucketPlayers, setBucketPlayers) {
    if (!item || !monitor || !item)
        return;
    var dropResult = monitor.getDropResult();
    if (!dropResult)
        return;
    var teamA = filterOutPlayerById(bucketPlayers.teamA, item);
    var teamB = filterOutPlayerById(bucketPlayers.teamB, item);
    var bench = filterOutPlayerById(bucketPlayers.bench, item);
    switch (dropResult.name) {
        case "teamA":
            teamA = __spreadArray(__spreadArray([], __read(teamA), false), [item], false);
            break;
        case "teamB":
            teamB = __spreadArray(__spreadArray([], __read(teamB), false), [item], false);
            break;
        default:
            bench = __spreadArray(__spreadArray([], __read(bench), false), [item], false);
            break;
    }
    var updatedBucketPlayers = {
        teamA: teamA,
        teamB: teamB,
        bench: bench,
    };
    setBucketPlayers(updatedBucketPlayers);
    return updatedBucketPlayers;
};
