import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { countryCodeToEmoji } from "./countries";
export var playerDisplayName = function (player) {
    if (!player) {
        return "Unknown Player";
    }
    var model = player.user || player.externalUser;
    if (model === undefined) {
        throw new Error("Cannot retrieve Player User!");
    }
    return "".concat(model.firstName, " ").concat(model.lastName);
};
export var PlayerDisplayNameLink = function (_a) {
    var player = _a.player, _b = _a.reverse, reverse = _b === void 0 ? false : _b;
    var displayName = playerDisplayName(player);
    if (!player) {
        return _jsx(Fragment, { children: displayName });
    }
    return (_jsx(Link, { style: { textDecoration: "none" }, to: "/players/".concat(player.id, "/"), children: reverse
            ? "".concat(displayName, " ").concat(countryCodeToEmoji(player.nationality))
            : "".concat(countryCodeToEmoji(player.nationality), " ").concat(displayName) }, player.id));
};
