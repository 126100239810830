import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, Divider, Grid, Stack, Typography, } from "@mui/material";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import React, { useContext } from "react";
import { useListIntraSquadMatchesByPlayerId, useRetrievePlayerByUserId, useRetrieveTeamsByPlayerId, } from "./ApiProvider";
import Landing from "./Landing";
import { UserContext } from "./auth/UserProvider";
import { ErrorView } from "./nav/Error";
import { Loading } from "./nav/Loading";
import { PlayerView } from "./players/PlayerView";
import { aggregatePlayerIntraSquadMatchStats } from "./stats/utils";
var Count = function (_a) {
    var word = _a.word, n = _a.n;
    return (_jsxs(Stack, { style: { textAlign: "center" }, spacing: 1, children: [_jsx(Typography, { variant: "caption", display: "block", gutterBottom: true, children: word }), _jsx(Typography, { variant: "h5", children: _jsx("strong", { children: n }) })] }));
};
var Home = function () {
    var _a;
    var _b = useContext(UserContext), contextUser = _b.user, isContextUserLoading = _b.loading;
    var _c = useRetrievePlayerByUserId({ userId: contextUser === null || contextUser === void 0 ? void 0 : contextUser.pk }), isPlayerLoading = _c.isLoading, playerError = _c.error, player = _c.data;
    var _d = useListIntraSquadMatchesByPlayerId({
        playerId: player === null || player === void 0 ? void 0 : player.id,
    }), isIntraSquadMatchesLoading = _d.isLoading, intraSquadMatchesError = _d.error, intraSquadMatches = _d.data;
    var _e = useRetrieveTeamsByPlayerId({
        playerId: player === null || player === void 0 ? void 0 : player.id,
    }), isTeamsLoading = _e.isLoading, teamsError = _e.error, teams = _e.data;
    var combinedError = playerError || intraSquadMatchesError || teamsError;
    var loading = isPlayerLoading ||
        isIntraSquadMatchesLoading ||
        isTeamsLoading ||
        isContextUserLoading;
    if (!contextUser && !isContextUserLoading) {
        return _jsx(Landing, {});
    }
    if (loading) {
        return _jsx(Loading, {});
    }
    if (combinedError) {
        return _jsx(ErrorView, { error: playerError });
    }
    if (player === undefined || intraSquadMatches === undefined) {
        return (_jsx(ErrorView, { error: new Error("Failed to load user data correctly.") }));
    }
    var pastMatches = intraSquadMatches
        .filter(function (match) { return new Date(match.kickOff) < new Date(); })
        .sort(function (a, b) { return new Date(b.kickOff).getTime() - new Date(a.kickOff).getTime(); });
    var formatLatestActivity = function (intraSquadMatch) {
        return intraSquadMatch ? (_jsx(React.Fragment, { children: _jsxs(Link, { to: "/intra-squad-matches/".concat(intraSquadMatch.id), children: [_jsx("strong", { children: "Intra-Squad Match" }), " \u2022", " ", format(new Date(intraSquadMatch.kickOff), "dd MMM yyyy"), "`"] }) })) : (_jsx(React.Fragment, { children: "No recent activity" }));
    };
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 4, lg: 4, children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(PlayerView, { player: player, variant: "h6" }), _jsx(Divider, { sx: { my: 2 } }), _jsx(Typography, { variant: "caption", align: "left", display: "block", children: "Latest Activity" }), _jsx(Typography, { variant: "body1", align: "left", display: "block", children: formatLatestActivity(pastMatches[0]) })] }) }) }), _jsx(Grid, { item: true, xs: 12, md: 5, lg: 5, children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h6", align: "center", children: "Intra-Squad Matches" }), _jsx(Divider, {}), _jsx(Box, { sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "background.paper",
                                    color: "text.secondary",
                                    margin: 2,
                                }, children: _jsxs(Stack, { spacing: 2, direction: "row", alignItems: "center", children: [_jsx(Count, { word: "Goals", n: ((_a = aggregatePlayerIntraSquadMatchStats(intraSquadMatches)[player.id]) === null || _a === void 0 ? void 0 : _a.goalsScored) || 0 }), _jsx(Divider, { orientation: "vertical", flexItem: true }), _jsx(Count, { word: "Matches", n: intraSquadMatches.length })] }) })] }) }) }), _jsx(Grid, { item: true, xs: 12, md: 3, lg: 3, children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Link, { to: "/teams", style: { textDecoration: "none" }, children: _jsx(Typography, { variant: "h6", align: "center", color: "primary", children: "Teams" }) }), _jsx(Divider, {}), _jsx(Box, { sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "background.paper",
                                    color: "text.secondary",
                                    margin: 2,
                                }, children: _jsx(Count, { word: "Count", n: (teams === null || teams === void 0 ? void 0 : teams.length) || 0 }) })] }) }) })] }));
};
export default Home;
