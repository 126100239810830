var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "../ApiProvider";
import { UserContext } from "../auth/UserProvider";
import { getStaticFileUrl } from "../utils/staticFiles";
function NavBar() {
    var _a = useContext(UserContext), user = _a.user, setUser = _a.setUser, setLoading = _a.setLoading;
    var _b = __read(useState(null), 2), anchorElNav = _b[0], setAnchorElNav = _b[1];
    var _c = __read(useState(null), 2), anchorElUser = _c[0], setAnchorElUser = _c[1];
    var handleCloseNavMenu = function () {
        setAnchorElNav(null);
    };
    var handleOpenUserMenu = function (event) {
        setAnchorElUser(event.currentTarget);
    };
    var handleOpenNavMenu = function (event) {
        setAnchorElNav(event.currentTarget);
    };
    var handleCloseUserMenu = function () {
        setAnchorElUser(null);
    };
    var navigate = useNavigate();
    var mutation = useLogout(function () {
        setLoading(true);
    }, function () {
        setUser(null);
        navigate("/login");
        setLoading(false);
    }, function (e) {
        console.error(e);
    });
    return (_jsx(Box, { sx: { flexGrow: 1 }, children: _jsx(AppBar, { position: "static", children: _jsx(Container, { maxWidth: "lg", children: _jsxs(Toolbar, { disableGutters: true, children: [_jsx("img", { alt: "Log.Soccer", height: "50px", src: getStaticFileUrl("images", "white-logo-no-background.png") }), user ? (_jsxs(React.Fragment, { children: [_jsxs(Box, { sx: { flexGrow: 1, display: { xs: "flex", md: "none" } }, children: [_jsx(IconButton, { size: "large", "aria-label": "account of current user", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleOpenNavMenu, color: "inherit", children: _jsx(MenuIcon, {}) }), _jsx(Menu, { id: "menu-appbar", anchorEl: anchorElNav, anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }, keepMounted: true, transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            }, open: Boolean(anchorElNav), onClose: handleCloseNavMenu, sx: {
                                                display: { xs: "block", md: "none" },
                                            }, children: _jsx(MenuItem, { component: Link, to: "/teams", children: _jsx(Typography, { textAlign: "center", children: "Teams" }) }, "Teams") })] }), _jsx(Box, { sx: { flexGrow: 1, display: { xs: "none", md: "flex" } }, children: _jsx(Button, { to: "/teams", component: Link, sx: { my: 2, color: "white", display: "block" }, children: "Teams" }, "Teams") }), _jsxs(Box, { sx: { flexGrow: 0, display: { xs: "none", md: "flex" } }, children: [_jsx(IconButton, { onClick: handleOpenUserMenu, sx: { p: 0 }, children: _jsx(Avatar, { alt: user.firstName, src: user.picture, imgProps: { referrerPolicy: "no-referrer" } }) }), _jsxs(Menu, { sx: { mt: "45px" }, id: "menu-appbar", anchorEl: anchorElUser, anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                            }, keepMounted: true, transformOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                            }, open: Boolean(anchorElUser), onClose: handleCloseUserMenu, children: [_jsx(MenuItem, { onClick: handleCloseUserMenu, children: _jsx(Button, { component: Link, to: "/account", children: "My Account" }) }, "Account"), _jsx(MenuItem, { onClick: handleCloseUserMenu, children: _jsx(Button, { onClick: function () {
                                                            mutation.mutate();
                                                        }, children: "Logout" }) }, "Logout")] })] })] })) : (_jsx(Button, { color: "inherit", component: "a", href: "/login", children: "Login" }))] }) }) }) }));
}
export default NavBar;
