var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getData } from "country-list";
var HOME_NATIONS = [
    {
        code: "GB-ENG",
        name: "England",
        emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    },
    {
        code: "GB-SCT",
        name: "Scotland",
        emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    },
    {
        code: "GB-WLS",
        name: "Wales",
        emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    },
];
export var countries = __spreadArray(__spreadArray([], __read(HOME_NATIONS), false), __read(getData()), false).sort(function (a, b) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
});
export var countryCodeToEmoji = function (countryCode) {
    var _a;
    return (((_a = HOME_NATIONS.find(function (o) { return o.code === countryCode; })) === null || _a === void 0 ? void 0 : _a.emoji) || String.fromCodePoint.apply(String, __spreadArray([], __read(__spreadArray([], __read(countryCode.toUpperCase()), false).map(function (char) { return char.charCodeAt(0) + 127397; })), false)));
};
