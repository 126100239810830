var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TuneIcon from "@mui/icons-material/Tune";
import { Alert, Avatar, AvatarGroup, Box, Button, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Stack, Tab, Tabs, Typography, } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteTeam, useListIntraSquadMatchesByTeamId, useRetrievePlayerByUserId, useRetrievePlayersByTeamId, useRetrieveTeam, useRetrieveTeamPreferences, } from "../ApiProvider";
import { formatIntraSquadMatchResult } from "../matches/intraSquadMatchUtils";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { NotFound } from "../nav/NotFound";
import { PlayerAvatar } from "../players/PlayerAvatar";
import { PlayerStack } from "../players/PlayerStack";
import { PlayerView } from "../players/PlayerView";
import { IntraSquadMatchStatsTable } from "../stats/IntraSquadMatchStatsTable";
import { DeleteDialog } from "../utils/DeleteDialog";
import { useNumericParams } from "../utils/useNumericParams";
import TeamColorsCircle from "./TeamColors";
export var TabPanel = function (props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-".concat(index), "aria-labelledby": "simple-tab-".concat(index) }, other, { children: value === index && (_jsx(Box, { sx: { p: 3 }, children: _jsx(Typography, { children: children }) })) })));
};
export var a11yProps = function (index) {
    return {
        id: "simple-tab-".concat(index),
        "aria-controls": "simple-tabpanel-".concat(index),
    };
};
var Team = function () {
    var id = useNumericParams().id;
    var navigate = useNavigate();
    var _a = __read(useState(false), 2), showDelete = _a[0], setShowDelete = _a[1];
    var _b = __read(useState(undefined), 2), deleteError = _b[0], setDeleteError = _b[1];
    var _c = __read(useState(false), 2), deleteLoading = _c[0], setDeleteLoading = _c[1];
    var _d = __read(useState(0), 2), value = _d[0], setValue = _d[1];
    var _e = useRetrieveTeam({ id: id }), isTeamLoading = _e.isLoading, error = _e.error, team = _e.data;
    var _f = useRetrievePlayerByUserId({
        userId: (team === null || team === void 0 ? void 0 : team.createdBy) ? Number(team.createdBy) : undefined,
    }), isManagerLoading = _f.isLoading, managerError = _f.error, manager = _f.data;
    var _g = useListIntraSquadMatchesByTeamId({ teamId: id }), intraSquadMatchesIsLoading = _g.isLoading, intraSquadMatchesError = _g.error, intraSquadMatches = _g.data;
    var _h = useRetrieveTeamPreferences({ teamId: id }), teamPreferencesIsLoading = _h.isLoading, teamPreferencesError = _h.error, teamPreference = _h.data;
    var _j = useRetrievePlayersByTeamId({ teamId: id }), playersIsLoading = _j.isLoading, playersError = _j.error, players = _j.data;
    var mutation = useDeleteTeam({
        id: id,
        onMutate: function () {
            setDeleteLoading(true);
        },
        onSuccess: function () {
            navigate("/teams/");
        },
        onError: function (error) { return setDeleteError(error); },
    });
    var isLoading = isTeamLoading ||
        deleteLoading ||
        intraSquadMatchesIsLoading ||
        playersIsLoading ||
        teamPreferencesIsLoading ||
        isManagerLoading;
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var handleDelete = function () {
        mutation.mutate();
    };
    if (isLoading) {
        return _jsx(Loading, {});
    }
    if (error ||
        intraSquadMatchesError ||
        playersError ||
        teamPreferencesError ||
        intraSquadMatches === undefined ||
        players === undefined ||
        managerError ||
        !team ||
        !teamPreference ||
        !manager) {
        return _jsx(ErrorView, { error: error || intraSquadMatchesError });
    }
    if (id === undefined) {
        return _jsx(NotFound, {});
    }
    return (_jsxs(React.Fragment, { children: [deleteError && (_jsxs(Alert, { variant: "outlined", severity: "error", children: ["Failed to delete team \u2014 ", deleteError.message, "!"] })), _jsx(DeleteDialog, { open: showDelete, title: "Delete ".concat(team.name, "?"), onCancel: function () { return setShowDelete(false); }, onConfirm: handleDelete }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 10, children: [_jsxs(Stack, { alignItems: "center", spacing: 2, direction: "row", children: [_jsx(Avatar, { src: team.logo || "", alt: team.name }), _jsx(Typography, { variant: "h5", children: team.name })] }), _jsx(Typography, { variant: "caption", fontStyle: "italic", children: team.nickname })] }), _jsx(Grid, { item: true, xs: 2, children: _jsxs(Stack, { direction: "row", spacing: 1, children: [_jsx(IconButton, { "aria-label": "edit", children: _jsx(Link, { to: "/teams/".concat(id, "/edit/"), children: _jsx(EditIcon, {}) }) }), _jsx(IconButton, { "aria-label": "preferences", children: _jsx(Link, { to: "/teams/".concat(team.id, "/preferences/edit/"), children: _jsx(TuneIcon, {}) }) }), _jsx(IconButton, { "aria-label": "delete", onClick: function () {
                                        setShowDelete(true);
                                    }, children: _jsx(DeleteIcon, {}) })] }) })] }), _jsx(Divider, { sx: { margin: "20px" } }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 9, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: "divider" }, children: _jsxs(Tabs, { value: value, onChange: handleChange, "aria-label": "basic tabs example", children: [_jsx(Tab, __assign({ label: "Intra-Squad Matches" }, a11yProps(0))), _jsx(Tab, __assign({ label: "Intra-Squad Match Stats" }, a11yProps(1))), _jsx(Tab, __assign({ label: "Squad" }, a11yProps(2)))] }) }), _jsx(TabPanel, { value: value, index: 0, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 9, children: _jsx(Typography, { variant: "h6", children: "Latest Intra-Squad Matches" }) }), _jsx(Grid, { item: true, xs: 3, children: _jsx(Link, { to: "/teams/".concat(id, "/intra-squad-match/create/"), children: _jsx(Button, { variant: "contained", color: "primary", children: "Add Intra-Squad Match" }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(List, { children: intraSquadMatches && intraSquadMatches.length > 0 ? (intraSquadMatches.map(function (intraSquadMatch) {
                                                    return (_jsx(ListItem, { disablePadding: true, children: _jsx(ListItemButton, { component: Link, to: "/intra-squad-matches/".concat(intraSquadMatch.id, "/"), children: _jsx(ListItemText, { secondary: format(new Date(intraSquadMatch.kickOff), "MMMM do yyyy @ H:mma"), primary: formatIntraSquadMatchResult(intraSquadMatch.intraSquadMatchResult, teamPreference) }) }) }));
                                                })) : (_jsx(React.Fragment, { children: _jsx("div", { children: "No Intra-Squad Matches!" }) })) }) })] }) }), _jsx(TabPanel, { value: value, index: 1, children: _jsx(IntraSquadMatchStatsTable, { intraSquadMatches: intraSquadMatches, players: players }) }), _jsx(TabPanel, { value: value, index: 2, children: _jsx(PlayerStack, { Players: players }) })] }), _jsx(Grid, { item: true, xs: 3, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Typography, { variant: "h6", children: "Colors" }), _jsx(Box, { sx: { width: "50%", aspectRatio: "1" }, children: _jsx(TeamColorsCircle, { color1: team.primaryColour, color2: team.secondaryColour }) }), _jsx(Divider, { sx: { margin: "20px" } }), _jsx(Typography, { variant: "h6", children: "Manager" }), _jsx(PlayerView, { player: manager, variant: "h6" }), _jsx(Divider, { sx: { margin: "20px" } }), _jsxs(Typography, { variant: "h6", children: [players.length, " Players"] }), _jsx(Stack, { style: { marginTop: 2 }, direction: "row", children: _jsx(AvatarGroup, { max: 4, children: players.map(function (player) {
                                            return _jsx(PlayerAvatar, { player: player }, player.id);
                                        }) }) }), _jsx(Divider, { sx: { margin: "20px" } }), _jsx(Typography, { variant: "h6", children: "Add Players to Team" }), _jsx(Link, { to: "/teams/".concat(team.id, "/add-player/"), children: _jsx(Button, { variant: "contained", color: "primary", children: "Add Player" }) })] }) })] })] }));
};
export default Team;
