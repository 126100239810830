var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider, Grid, Stack, TextField, Typography, } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PlayerDisplayNameLink } from "../players/PlayerDisplayNameLink";
import { findPlayerById } from "../players/utils";
import { findPlayerIntraSquadMatchStatByPlayerId } from "./utils";
export var IntraSquadMatchStatsForm = function (_a) {
    var _b, _c;
    var intraSquadMatch = _a.intraSquadMatch, onSubmit = _a.onSubmit, players = _a.players, teamPreferences = _a.teamPreferences;
    var getPlayerIntraSquadMatchStat = findPlayerIntraSquadMatchStatByPlayerId((intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.playerIntraSquadMatchStats) || []);
    var defaultPlayerIntraSquadMatchStats = {
        playerIntraSquadMatchStats: __spreadArray(__spreadArray([], __read(intraSquadMatch.teamAPlayers), false), __read(intraSquadMatch.teamBPlayers), false).map(function (playerId) {
            var playerIntraSquadMatchStat = getPlayerIntraSquadMatchStat(playerId);
            return {
                teamId: intraSquadMatch.team,
                intraSquadMatchId: intraSquadMatch.id,
                playerId: playerId,
                goalsScored: playerIntraSquadMatchStat === null || playerIntraSquadMatchStat === void 0 ? void 0 : playerIntraSquadMatchStat.goalsScored,
                yellowCards: playerIntraSquadMatchStat === null || playerIntraSquadMatchStat === void 0 ? void 0 : playerIntraSquadMatchStat.yellowCards,
                redCards: playerIntraSquadMatchStat === null || playerIntraSquadMatchStat === void 0 ? void 0 : playerIntraSquadMatchStat.redCards,
                id: playerIntraSquadMatchStat === null || playerIntraSquadMatchStat === void 0 ? void 0 : playerIntraSquadMatchStat.id,
            };
        }),
    };
    var _d = useForm({
        defaultValues: defaultPlayerIntraSquadMatchStats,
    }), controlPlayerIntraSquadMatchStats = _d.control, handleSubmitPlayerIntraSquadMatchStats = _d.handleSubmit;
    var _e = useForm({
        defaultValues: {
            intraSquadMatchId: intraSquadMatch.id,
            teamAScore: (_b = intraSquadMatch.intraSquadMatchResult) === null || _b === void 0 ? void 0 : _b.teamAScore,
            teamBScore: (_c = intraSquadMatch.intraSquadMatchResult) === null || _c === void 0 ? void 0 : _c.teamBScore,
        },
    }), controlIntraSquadMatchResult = _e.control, handleSubmitIntraSquadMatchResult = _e.handleSubmit, errorsIntraSquadMatchResult = _e.formState.errors;
    var handleFormsSubmit = function (data1) {
        handleSubmitPlayerIntraSquadMatchStats(function (data2) {
            return onSubmit(data1, data2.playerIntraSquadMatchStats);
        })();
    };
    return (_jsxs(Box, { component: "form", onSubmit: handleSubmitIntraSquadMatchResult(handleFormsSubmit), noValidate: true, sx: { mt: 3 }, children: [_jsx(Grid, { container: true, justifyContent: "center", alignItems: "center", children: _jsxs(Stack, { spacing: 2, direction: "row", children: [_jsx(Typography, { variant: "h6", children: teamPreferences.teamAName }), _jsx(Controller, { name: "teamAScore", control: controlIntraSquadMatchResult, rules: { required: "Score is required" }, render: function (_a) {
                                var field = _a.field;
                                return (_jsx(TextField, __assign({}, field, { margin: "normal", required: true, fullWidth: true, type: "number", inputProps: { min: 0 }, id: "teamAScore", autoFocus: true, error: !!errorsIntraSquadMatchResult.teamAScore, helperText: errorsIntraSquadMatchResult.teamAScore
                                        ? errorsIntraSquadMatchResult.teamAScore.message
                                        : "" })));
                            } }), _jsx(Typography, { variant: "h4", children: "-" }), _jsx(Controller, { name: "teamBScore", control: controlIntraSquadMatchResult, rules: { required: "Score is required" }, render: function (_a) {
                                var field = _a.field;
                                return (_jsx(TextField, __assign({}, field, { margin: "normal", required: true, fullWidth: true, type: "number", inputProps: { min: 0 }, id: "teamBScore", autoFocus: true, error: !!errorsIntraSquadMatchResult.teamBScore, helperText: errorsIntraSquadMatchResult.teamBScore
                                        ? errorsIntraSquadMatchResult.teamBScore.message
                                        : "" })));
                            } }), _jsx(Typography, { variant: "h6", children: teamPreferences.teamBName })] }) }), _jsx(Divider, { sx: { margin: "20px 0" } }), (intraSquadMatch.teamAPlayers.length > 0 ||
                intraSquadMatch.teamBPlayers.length > 0) && (_jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 6, children: [_jsxs(Typography, { variant: "h5", children: [teamPreferences.teamAName, " Players"] }), _jsx(Stack, { rowGap: 2, children: (intraSquadMatch.teamAPlayers || [])
                                    .map(findPlayerById(players || []))
                                    .map(function (player, i) { return (_jsxs(Grid, { container: true, spacing: 2, alignItems: "center", children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(PlayerDisplayNameLink, { player: player }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Controller, { name: "playerIntraSquadMatchStats.".concat(i, ".goalsScored"), control: controlPlayerIntraSquadMatchStats, render: function (_a) {
                                                    var field = _a.field;
                                                    return (_jsx(TextField, __assign({}, field, { type: "number", label: "Goals", inputProps: { min: 0 }, id: "".concat(i, ".goalsScored") })));
                                                } }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Controller, { name: "playerIntraSquadMatchStats.".concat(i, ".yellowCards"), control: controlPlayerIntraSquadMatchStats, render: function (_a) {
                                                    var field = _a.field;
                                                    return (_jsx(TextField, __assign({}, field, { type: "number", label: "Yellow Cards", inputProps: { min: 0 }, id: "".concat(i, ".yellowCards") })));
                                                } }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Controller, { name: "playerIntraSquadMatchStats.".concat(i, ".redCards"), control: controlPlayerIntraSquadMatchStats, render: function (_a) {
                                                    var field = _a.field;
                                                    return (_jsx(TextField, __assign({}, field, { type: "number", label: "Red Cards", inputProps: { min: 0 }, id: "".concat(i, ".redCards") })));
                                                } }) })] }, player === null || player === void 0 ? void 0 : player.id)); }) })] }), _jsxs(Grid, { item: true, xs: 6, children: [_jsxs(Typography, { variant: "h5", textAlign: "right", children: [teamPreferences.teamBName, " Players"] }), _jsx(Stack, { rowGap: 2, children: (intraSquadMatch.teamBPlayers || [])
                                    .map(findPlayerById(players || []))
                                    .map(function (player, i) {
                                    var j = i + intraSquadMatch.teamAPlayers.length;
                                    return (_jsxs(Grid, { container: true, spacing: 2, alignItems: "center", children: [_jsx(Grid, { item: true, xs: 2, children: _jsx(Controller, { name: "playerIntraSquadMatchStats.".concat(j, ".goalsScored"), control: controlPlayerIntraSquadMatchStats, render: function (_a) {
                                                        var field = _a.field;
                                                        return (_jsx(TextField, __assign({}, field, { type: "number", label: "Goals", inputProps: { min: 0 }, id: "".concat(j, ".goalsScored") })));
                                                    } }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Controller, { name: "playerIntraSquadMatchStats.".concat(j, ".yellowCards"), control: controlPlayerIntraSquadMatchStats, render: function (_a) {
                                                        var field = _a.field;
                                                        return (_jsx(TextField, __assign({}, field, { type: "number", label: "Yellow Cards", inputProps: { min: 0 }, id: "".concat(j, ".yellowCards") })));
                                                    } }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Controller, { name: "playerIntraSquadMatchStats.".concat(j, ".redCards"), control: controlPlayerIntraSquadMatchStats, render: function (_a) {
                                                        var field = _a.field;
                                                        return (_jsx(TextField, __assign({}, field, { type: "number", label: "Red Cards", inputProps: { min: 0 }, id: "".concat(j, ".redCards") })));
                                                    } }) }), _jsx(Grid, { item: true, xs: 6, textAlign: "right", children: _jsx(PlayerDisplayNameLink, { reverse: true, player: player }) })] }, player === null || player === void 0 ? void 0 : player.id));
                                }) })] })] })), _jsx(Button, { type: "submit", fullWidth: true, variant: "contained", sx: { mt: 3 }, children: "Submit Stats" })] }));
};
