import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { playerDisplayName } from "./PlayerDisplayNameLink";
export var PlayerAvatar = function (_a) {
    var _b, _c;
    var player = _a.player, _d = _a.size, size = _d === void 0 ? 56 : _d;
    var displayName = playerDisplayName(player);
    var tooltip = (_jsx(Tooltip, { title: displayName, arrow: true, children: _jsx(Avatar, { src: (_b = player === null || player === void 0 ? void 0 : player.user) === null || _b === void 0 ? void 0 : _b.picture, alt: displayName, sx: { width: size, height: size }, children: (_c = displayName.match(/\b(\w)/g)) === null || _c === void 0 ? void 0 : _c.join("") }) }));
    if (!player) {
        return tooltip;
    }
    return (_jsx(Link, { style: { textDecoration: "none" }, to: "/players/".concat(player === null || player === void 0 ? void 0 : player.id, "/"), children: tooltip }, player === null || player === void 0 ? void 0 : player.id));
};
