var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SquareIcon from "@mui/icons-material/Square";
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography, } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "date-fns";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRetrievePlayersByTeamId, useRetrieveTeam, useRetrieveTeamPreferences, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { NotFound } from "../nav/NotFound";
import { PlayerAvatar } from "../players/PlayerAvatar";
import { PlayerDisplayNameLink } from "../players/PlayerDisplayNameLink";
import { findPlayerById } from "../players/utils";
import { findPlayerIntraSquadMatchStatByPlayerId } from "../stats/utils";
var IntraSquadMatch = function () {
    var _a, _b, _c;
    var id = useParams().id;
    var navigate = useNavigate();
    var _d = __read(React.useState(false), 2), showDelete = _d[0], setShowDelete = _d[1];
    var _e = __read(useState(undefined), 2), deleteError = _e[0], setDeleteError = _e[1];
    var _f = __read(useState(false), 2), deleteLoading = _f[0], setDeleteLoading = _f[1];
    var _g = useQuery({
        queryKey: ["useRetrieveIntraSquadMatch-".concat(id)],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/intra-squad-matches/".concat(id, "/"), {})];
                    case 1: return [2 /*return*/, (_a.sent())
                            .data];
                }
            });
        }); },
    }), intraSquadMatchIsLoading = _g.isLoading, intraSquadMatchError = _g.error, intraSquadMatch = _g.data;
    var _h = useRetrieveTeam({ id: intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team }), useRetrieveTeamIsLoading = _h.isLoading, useRetrieveTeamError = _h.error, team = _h.data;
    var _j = useRetrieveTeamPreferences({ teamId: intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team }), useRetrieveTeamPreferenceIsLoading = _j.isLoading, useRetrieveTeamPreferenceError = _j.error, teamPreference = _j.data;
    var _k = useRetrievePlayersByTeamId({ teamId: intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team }), players = _k.data, playersError = _k.error, playersIsLoading = _k.isLoading;
    var getPlayer = findPlayerById(players || []);
    var getPlayerIntraSquadMatchStat = findPlayerIntraSquadMatchStatByPlayerId((intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.playerIntraSquadMatchStats) || []);
    var mutation = useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.delete("/api/intra-squad-matches/".concat(id, "/"))];
            });
        }); },
        onMutate: function () {
            setDeleteLoading(true);
        },
        onSuccess: function () {
            return navigate("/teams/".concat(intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team));
        },
        onError: function (error) { return setDeleteError(error); },
    });
    if (intraSquadMatchIsLoading ||
        deleteLoading ||
        playersIsLoading ||
        useRetrieveTeamPreferenceIsLoading ||
        useRetrieveTeamIsLoading) {
        return _jsx(Loading, {});
    }
    if (id === undefined) {
        return _jsx(NotFound, {});
    }
    if (useRetrieveTeamError ||
        intraSquadMatchError ||
        deleteError ||
        useRetrieveTeamPreferenceError ||
        playersError ||
        !intraSquadMatch ||
        !teamPreference ||
        !team) {
        return _jsx(ErrorView, {});
    }
    var handleCloseDeleteDialog = function () {
        setShowDelete(false);
    };
    var handleDelete = function () {
        mutation.mutate();
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(Dialog, { open: showDelete, onClose: handleCloseDeleteDialog, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: "Delete Intra-Squad Match?" }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleCloseDeleteDialog, children: "Cancel" }), _jsx(Button, { color: "error", onClick: handleDelete, autoFocus: true, children: "Delete" })] })] }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 9, children: [_jsxs(Typography, { variant: "h5", children: [_jsx(Link, { style: { textDecoration: "none" }, to: "/teams/".concat(team.id), children: team.name }), " ", "Intra-Squad Match"] }), _jsx(Typography, { variant: "caption", children: format(new Date(intraSquadMatch.kickOff), "MMMM do yyyy @ H:mma") })] }), _jsx(Grid, { item: true, xs: 3, children: _jsxs(Stack, { direction: "row", spacing: 1, children: [intraSquadMatch.intraSquadMatchResult === null &&
                                    ((_a = intraSquadMatch.playerIntraSquadMatchStats) === null || _a === void 0 ? void 0 : _a.length) === 0 && (_jsx(Link, { to: "/intra-squad-matches/".concat(id, "/stats/create"), children: _jsx(Button, { variant: "contained", color: "primary", children: "Add Match Stats" }) })), _jsx(IconButton, { "aria-label": "update", children: _jsx(Link, { to: "/intra-squad-matches/".concat(id, "/update"), children: _jsx(EditIcon, {}) }) }), _jsx(IconButton, { "aria-label": "delete", children: _jsx(DeleteIcon, { onClick: function () {
                                            setShowDelete(true);
                                        } }) })] }) }), _jsxs(Grid, { item: true, xs: 4, children: [_jsx(Typography, { variant: "h6", children: teamPreference.teamAName }), _jsx(List, { sx: {
                                    width: "100%",
                                    maxWidth: 360,
                                    bgcolor: "background.paper",
                                }, children: (_b = intraSquadMatch.teamAPlayers) === null || _b === void 0 ? void 0 : _b.map(function (p) {
                                    var player = getPlayer(p);
                                    var stats = getPlayerIntraSquadMatchStat(p);
                                    return (_jsxs(ListItem, { children: [_jsx(ListItemAvatar, { children: _jsx(PlayerAvatar, { player: player, size: 48 }) }), _jsx(ListItemText, { primary: _jsx(PlayerDisplayNameLink, { player: player }) }), _jsxs(Stack, { direction: "row", children: [stats &&
                                                        stats.goalsScored !== undefined &&
                                                        stats.goalsScored > 0 && (_jsxs(React.Fragment, { children: [_jsx(SportsSoccerIcon, {}), _jsx(Typography, { children: stats.goalsScored > 1
                                                                    ? "(".concat(stats.goalsScored, ")")
                                                                    : "" })] })), stats &&
                                                        stats.yellowCards !== undefined &&
                                                        stats.yellowCards > 0 && (_jsxs(React.Fragment, { children: [_jsx(SquareIcon, { style: { color: "#ebcc34" } }), _jsx(Typography, { children: stats.yellowCards > 1
                                                                    ? "(".concat(stats.yellowCards, ")")
                                                                    : "" })] })), stats &&
                                                        stats.redCards !== undefined &&
                                                        stats.redCards > 0 && (_jsxs(React.Fragment, { children: [_jsx(SquareIcon, { style: { color: "#e83c1a" } }), _jsx(Typography, { children: stats.redCards > 1 ? "(".concat(stats.redCards, ")") : "" })] }))] })] }));
                                }) })] }), _jsx(Grid, { item: true, xs: 2, children: intraSquadMatch.intraSquadMatchResult ? (_jsxs(Typography, { variant: "h4", children: [intraSquadMatch.intraSquadMatchResult.teamAScore, " -", " ", intraSquadMatch.intraSquadMatchResult.teamBScore] })) : (_jsx(Typography, { variant: "h4", children: "v" })) }), _jsxs(Grid, { item: true, xs: 4, children: [_jsx(Typography, { variant: "h6", children: teamPreference.teamBName }), _jsx(List, { sx: {
                                    width: "100%",
                                    maxWidth: 360,
                                    bgcolor: "background.paper",
                                }, children: (_c = intraSquadMatch.teamBPlayers) === null || _c === void 0 ? void 0 : _c.map(function (p) {
                                    var player = getPlayer(p);
                                    var stats = getPlayerIntraSquadMatchStat(p);
                                    return (_jsxs(ListItem, { children: [_jsx(ListItemAvatar, { children: _jsx(PlayerAvatar, { player: player, size: 48 }) }), _jsx(ListItemText, { primary: _jsx(PlayerDisplayNameLink, { player: player }) }), _jsxs(Stack, { direction: "row", children: [stats &&
                                                        stats.goalsScored !== undefined &&
                                                        stats.goalsScored > 0 && (_jsxs(React.Fragment, { children: [_jsx(SportsSoccerIcon, {}), _jsx(Typography, { children: stats.goalsScored > 1
                                                                    ? "(".concat(stats.goalsScored, ")")
                                                                    : "" })] })), stats &&
                                                        stats.yellowCards !== undefined &&
                                                        stats.yellowCards > 0 && (_jsxs(React.Fragment, { children: [_jsx(SquareIcon, { style: { color: "#ebcc34" } }), _jsx(Typography, { children: stats.yellowCards > 1
                                                                    ? "(".concat(stats.yellowCards, ")")
                                                                    : "" })] })), stats &&
                                                        stats.redCards !== undefined &&
                                                        stats.redCards > 0 && (_jsxs(React.Fragment, { children: [_jsx(SquareIcon, { style: { color: "#e83c1a" } }), _jsx(Typography, { children: stats.redCards > 1 ? "(".concat(stats.redCards, ")") : "" })] }))] })] }, p));
                                }) })] }), _jsx(Grid, { item: true, xs: 1, children: (intraSquadMatch.intraSquadMatchResult !== null ||
                            intraSquadMatch.playerIntraSquadMatchStats !== null) && (_jsx(IconButton, { "aria-label": "update", children: _jsx(Link, { to: "/intra-squad-matches/".concat(id, "/stats/update/"), children: _jsx(EditIcon, {}) }) })) })] })] }));
};
export default IntraSquadMatch;
