import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TeamAvatar } from "./TeamAvatar";
export var TeamCard = function (_a) {
    var team = _a.team;
    return (_jsx(Card, { children: _jsx(CardContent, { children: _jsxs(Stack, { direction: "row", spacing: 2, alignItems: "center", children: [_jsx(TeamAvatar, { team: team }), _jsx(Typography, { component: Link, to: "/teams/".concat(team.id, "/"), sx: {
                            textDecoration: "none",
                            color: "inherit",
                        }, variant: "h6", children: team.name })] }) }) }));
};
