import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
export var NotFound = function () {
    var navigate = useNavigate();
    var handleGoHome = function () {
        navigate("/"); // Redirect to homepage or another route
    };
    return (_jsx(Container, { component: "main", maxWidth: "xs", children: _jsxs(Box, { sx: {
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
            }, children: [_jsx(ErrorOutlineIcon, { sx: { fontSize: 80, color: "error.main", mb: 3 } }), _jsx(Typography, { variant: "h3", gutterBottom: true, children: "404 - Missed Penalty!" }), _jsx(Typography, { variant: "h6", sx: { mb: 4 }, children: "You should not be here." }), _jsx(Button, { variant: "contained", color: "primary", onClick: handleGoHome, sx: { mt: 2 }, children: "Go Back to Home" })] }) }));
};
