var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddPlayerToTeam, useCreateTeam, useRetrievePlayerByUserId, } from "../ApiProvider";
import { UserContext } from "../auth/UserProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { TeamForm } from "./TeamForm";
export var CreateTeam = function () {
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState(undefined), 2), error = _b[0], setError = _b[1];
    var navigate = useNavigate();
    var _c = __read(useState(true), 2), addAsPlayer = _c[0], setAddAsPlayer = _c[1];
    var _d = useContext(UserContext), contextUser = _d.user, isContextUserLoading = _d.loading;
    var _e = useRetrievePlayerByUserId({ userId: contextUser === null || contextUser === void 0 ? void 0 : contextUser.pk }), player = _e.data, isLoading = _e.isLoading, playerError = _e.error;
    var onSuccess = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var team, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    team = data.data;
                    if (!addAsPlayer) return [3 /*break*/, 4];
                    if (!player) {
                        throw new Error("Must have a Player to add to Team!");
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, addPlayerToTeamMutation.mutateAsync({
                            teamId: team.id,
                            playerId: player.id,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error adding player to team:", error_1);
                    return [3 /*break*/, 4];
                case 4:
                    navigate("/teams/".concat(team.id, "/"));
                    return [2 /*return*/];
            }
        });
    }); };
    var onError = function (error) {
        setLoading(false);
        setError(error);
    };
    var mutation = useCreateTeam({
        onMutate: function () { return setLoading(true); },
        onSuccess: onSuccess,
        onError: onError,
    });
    var addPlayerToTeamMutation = useAddPlayerToTeam({
        onMutate: function () { return console.log("Adding player to team..."); },
        onSuccess: function (data) { return console.log("Player added to team:", data); },
        onError: function (error) { return console.error("Error adding player to team:", error); },
    });
    if (loading || isLoading || isContextUserLoading) {
        return _jsx(Loading, {});
    }
    if (playerError) {
        return _jsx(ErrorView, { error: playerError });
    }
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: !!error, message: "Failed to create team. Please try again.", onClose: function () { return setError(undefined); } }), _jsx(Typography, { variant: "h6", children: _jsx("strong", { children: "Create A Team" }) }), _jsx(TeamForm, { onSubmit: mutation.mutate, addAsPlayer: addAsPlayer, setAddAsPlayer: setAddAsPlayer })] }));
};
