var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateIntraSquadMatchResult, useCreateIntraSquadMatchStats, useRetrieveIntraSquadMatch, useRetrievePlayersByTeamId, useRetrieveTeamPreferences, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { useNumericParams } from "../utils/useNumericParams";
import { IntraSquadMatchStatsForm } from "./IntraSquadMatchStatsForm";
export var CreateIntraSquadMatchStats = function () {
    var id = useNumericParams().id;
    var _a = useRetrieveIntraSquadMatch({ id: id }), useRetrieveIntraSquadMatchIsLoading = _a.isLoading, useRetrieveIntraSquadMatchError = _a.error, intraSquadMatch = _a.data;
    var _b = useRetrievePlayersByTeamId({ teamId: intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team }), players = _b.data, playersError = _b.error, playersIsLoading = _b.isLoading;
    var _c = useRetrieveTeamPreferences({ teamId: intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team }), teamPreferences = _c.data, teamPreferencesError = _c.error, teamPreferencesIsLoading = _c.isLoading;
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var navigate = useNavigate();
    var _e = useCreateIntraSquadMatchStats(function () { }, function () { return undefined; }, function (error) {
        console.log(error);
    }), useCreateIntraSquadMatchStatsIsError = _e.isError, useCreateIntraSquadMatchStatsMutateAsync = _e.mutateAsync;
    var _f = useCreateIntraSquadMatchResult(intraSquadMatch, function () {
        setLoading(true);
    }, function () { return undefined; }, function (error) {
        console.log(error);
    }), useCreateIntraSquadMatchResultMutateAsyncIsError = _f.isError, useCreateIntraSquadMatchResultMutateAsync = _f.mutateAsync;
    if (teamPreferencesIsLoading ||
        loading ||
        playersIsLoading ||
        useRetrieveIntraSquadMatchIsLoading ||
        !intraSquadMatch) {
        return _jsx(Loading, {});
    }
    var onSubmit = function (intraSquadMatchResult, playerIntraSquadMatchStats) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log(intraSquadMatchResult, playerIntraSquadMatchStats);
                    return [4 /*yield*/, useCreateIntraSquadMatchStatsMutateAsync(Object.values(playerIntraSquadMatchStats))];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, useCreateIntraSquadMatchResultMutateAsync(intraSquadMatchResult)];
                case 2:
                    _a.sent();
                    navigate("/intra-squad-matches/".concat(id, "/"));
                    return [2 /*return*/];
            }
        });
    }); };
    if (!teamPreferences ||
        teamPreferencesError ||
        useRetrieveIntraSquadMatchError ||
        playersError ||
        players === undefined) {
        return (_jsx(ErrorView, { error: useRetrieveIntraSquadMatchError || playersError }));
    }
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: useCreateIntraSquadMatchResultMutateAsyncIsError, message: "Failed to create intra-squad match result! Please try again." }), _jsx(ErrorSnackbar, { open: useCreateIntraSquadMatchStatsIsError, message: "Failed to create player intra-squad match stats! Please try again." }), _jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Intra-Squad Match Stats for Team ", intraSquadMatch.team] }) }), _jsx(Typography, { variant: "subtitle2", children: format(new Date(intraSquadMatch.kickOff), "MMMM do yyyy @ H:mma") }), _jsx(IntraSquadMatchStatsForm, { players: players, onSubmit: onSubmit, intraSquadMatch: intraSquadMatch, teamPreferences: teamPreferences })] }));
};
