import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, } from "@mui/material";
export var DeleteDialog = function (_a) {
    var open = _a.open, title = _a.title, _b = _a.description, description = _b === void 0 ? "Are you sure you want to delete this item? This action cannot be undone." : _b, onCancel = _a.onCancel, onConfirm = _a.onConfirm, _c = _a.confirmText, confirmText = _c === void 0 ? "Delete" : _c, _d = _a.cancelText, cancelText = _d === void 0 ? "Cancel" : _d, _e = _a.confirmColor, confirmColor = _e === void 0 ? "error" : _e;
    return (_jsxs(Dialog, { open: open, onClose: onCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", PaperProps: {
            sx: {
                padding: 2,
                borderRadius: 2,
            },
        }, children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: _jsx(Typography, { variant: "h6", color: "error", children: title }) }), _jsx(DialogContent, { children: _jsx(DialogContentText, { id: "alert-dialog-description", children: description }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onCancel, variant: "outlined", color: "primary", children: cancelText }), _jsx(Button, { onClick: onConfirm, variant: "contained", color: confirmColor, sx: { ml: 1 }, children: confirmText })] })] }));
};
