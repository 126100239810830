var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
export var ExternalUserForm = function (_a) {
    var onSubmit = _a.onSubmit, externalUser = _a.externalUser, onCancel = _a.onCancel;
    var _b = useForm({
        defaultValues: {
            firstName: externalUser === null || externalUser === void 0 ? void 0 : externalUser.firstName,
            lastName: externalUser === null || externalUser === void 0 ? void 0 : externalUser.lastName,
            email: externalUser === null || externalUser === void 0 ? void 0 : externalUser.email,
        },
    }), control = _b.control, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    return (_jsxs(Box, { component: "form", role: "form", onSubmit: handleSubmit(function (data) {
            onSubmit(data);
        }), noValidate: true, sx: { mt: 1 }, children: [_jsx(Typography, { variant: "h6", children: " Personal Information" }), _jsx(Divider, { style: { margin: "15px 0" } }), _jsx(Controller, { name: "firstName", control: control, rules: { required: "First Name is required" }, render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, label: "First Name", required: true, autoComplete: "firstName", error: !!errors.firstName, helperText: (_b = errors.firstName) === null || _b === void 0 ? void 0 : _b.message, autoFocus: true })));
                } }), _jsx(Controller, { name: "lastName", control: control, rules: { required: "Last Name is required" }, render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, label: "Last Name", required: true, autoComplete: "lastName", error: !!errors.lastName, helperText: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message })));
                } }), _jsx(Controller, { name: "email", control: control, render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, type: "email", label: "Email", autoComplete: "email", error: !!errors.email, helperText: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message, autoFocus: true })));
                } }), _jsxs(Box, { sx: { display: "flex", justifyContent: "center", gap: 2, mt: 3, mb: 2 }, children: [_jsx(Button, { type: "submit", variant: "contained", color: "primary", size: "large", sx: { minWidth: 120 }, children: externalUser === undefined ? "Create" : "Update" }), _jsx(Button, { variant: "outlined", color: "secondary", size: "large", sx: { minWidth: 120 }, onClick: onCancel, children: "Cancel" })] })] }));
};
