import { jsx as _jsx } from "react/jsx-runtime";
import { AvatarGroup, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { TeamAvatar } from "./TeamAvatar";
export var TeamsAvatarStack = function (_a) {
    var teams = _a.teams;
    return (_jsx(Stack, { style: { marginTop: 2 }, direction: "row", children: _jsx(AvatarGroup, { max: 20, children: teams.map(function (team) {
                return (_jsx(Link, { style: { textDecoration: "none" }, to: "/teams/".concat(team.id, "/"), state: { team: team }, children: _jsx(TeamAvatar, { team: team }) }, team.id));
            }) }) }));
};
