var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Dragger } from "../dnd/Dragger";
import { Dropper } from "../dnd/Dropper";
import { playerDisplayName } from "../players/PlayerDisplayNameLink";
import { sortPlayers } from "../players/PlayerUtils";
import { findPlayerById } from "../players/utils";
import { onDrop } from "./Dnd";
export var TeamChooser = function (_a) {
    var players = _a.players, teamPreference = _a.teamPreference;
    var getPlayer = findPlayerById(players);
    var _b = useFormContext(), setValue = _b.setValue, getValues = _b.getValues;
    var _c = __read(React.useState({
        teamA: [],
        teamB: [],
        bench: [],
    }), 2), bucketPlayers = _c[0], setBucketPlayers = _c[1];
    React.useEffect(function () {
        var teamAPlayerIds = getValues().teamAPlayers;
        var teamBPlayerIds = getValues().teamBPlayers;
        setBucketPlayers({
            teamA: teamAPlayerIds.map(getPlayer),
            teamB: teamBPlayerIds.map(getPlayer),
            bench: players.filter(function (p) { return !teamAPlayerIds.includes(p.id) && !teamBPlayerIds.includes(p.id); }),
        });
    }, [players, setBucketPlayers, getValues, getPlayer]);
    var handleDrop = function (item, monitor) {
        var newBucketPlayers = onDrop(item, monitor, bucketPlayers, setBucketPlayers);
        setValue("teamAPlayers", (newBucketPlayers === null || newBucketPlayers === void 0 ? void 0 : newBucketPlayers.teamA.map(function (p) { return p.id; })) || []);
        setValue("teamBPlayers", (newBucketPlayers === null || newBucketPlayers === void 0 ? void 0 : newBucketPlayers.teamB.map(function (p) { return p.id; })) || []);
    };
    var sortedBenchPlayers = React.useMemo(function () {
        return bucketPlayers.bench.sort(sortPlayers);
    }, [bucketPlayers.bench]);
    return (_jsxs(Grid, { container: true, sx: { marginBottom: "20px" }, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 4, children: [_jsx(Typography, { variant: "subtitle2", "aria-label": "Team A: ".concat(teamPreference.teamAName), children: teamPreference.teamAName }), _jsx(Dropper, { name: "teamA", children: _jsx(Stack, { spacing: 2, children: bucketPlayers.teamA.map(function (player) {
                                return (_jsx(Dragger, { onDrop: handleDrop, item: player, label: "".concat(playerDisplayName(player)), deps: [bucketPlayers] }, player === null || player === void 0 ? void 0 : player.id));
                            }) }) })] }), _jsxs(Grid, { item: true, xs: 4, children: [_jsx(Typography, { variant: "subtitle2", "aria-label": "Team B: ".concat(teamPreference.teamBName), children: teamPreference.teamBName }), _jsx(Dropper, { name: "teamB", children: _jsx(Stack, { spacing: 2, children: bucketPlayers.teamB.map(function (player) {
                                return (_jsx(Dragger, { onDrop: handleDrop, item: player, label: "".concat(playerDisplayName(player)), deps: [bucketPlayers] }, player === null || player === void 0 ? void 0 : player.id));
                            }) }) })] }), _jsxs(Grid, { item: true, xs: 4, children: [_jsx(Typography, { variant: "subtitle2", children: "Bench" }), _jsx(Dropper, { name: "players", children: _jsx(Stack, { spacing: 2, children: sortedBenchPlayers.map(function (player) {
                                return (_jsx(Dragger, { onDrop: handleDrop, item: player, label: "".concat(playerDisplayName(player)), deps: [bucketPlayers] }, player === null || player === void 0 ? void 0 : player.id));
                            }) }) })] })] }));
};
