import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, AlertTitle, Box, Container } from "@mui/material";
import { AxiosError } from "axios";
import { NotFound } from "./NotFound";
export var ErrorView = function (_a) {
    var error = _a.error;
    if (error instanceof AxiosError && error.status === 404) {
        return _jsx(NotFound, {});
    }
    if (error instanceof Error) {
        return (_jsx(Container, { component: "main", maxWidth: "xs", children: _jsx(Box, { sx: {
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }, children: _jsxs(Alert, { severity: "error", children: [_jsx(AlertTitle, { children: "Error" }), "Sorry, something went wrong! ", _jsx("strong", { children: error.message })] }) }) }));
    }
    return (_jsx(Container, { component: "main", maxWidth: "xs", children: _jsx(Box, { sx: {
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }, children: _jsxs(Alert, { severity: "error", children: [_jsx(AlertTitle, { children: "Error" }), "Sorry, something went wrong!"] }) }) }));
};
