var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider, Grid, IconButton, Stack, Tab, Tabs, Typography, } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDeletePlayer, useListIntraSquadMatchesByPlayerId, useRetrievePlayer, useRetrieveTeamsByPlayerId, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { IntraSquadMatchStatsTable } from "../stats/IntraSquadMatchStatsTable";
import { a11yProps, TabPanel } from "../teams/Team";
import { TeamCard } from "../teams/TeamCard";
import { DeleteDialog } from "../utils/DeleteDialog";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { useNumericParams } from "../utils/useNumericParams";
import { playerDisplayName } from "./PlayerDisplayNameLink";
import { PlayerStatsCard } from "./PlayerStatsCard";
import { PlayerView } from "./PlayerView";
export var Player = function () {
    var id = useNumericParams().id;
    var navigate = useNavigate();
    var _a = __read(React.useState(false), 2), showDelete = _a[0], setShowDelete = _a[1];
    var _b = useRetrieveTeamsByPlayerId({ playerId: id }), teams = _b.data, teamsIsLoading = _b.isLoading, teamsError = _b.error;
    var teamIds = (teams === null || teams === void 0 ? void 0 : teams.map(function (team) { return team.id; })) || [];
    var mutation = useDeletePlayer({
        id: id,
        teamIds: teamIds,
        onMutate: function () {
            console.log("Deleting player...");
        },
        onSuccess: function () {
            return navigate("/");
        },
        onError: function (error) {
            console.error("Error deleting player", error);
        },
    });
    var _c = useRetrievePlayer({ id: id }), isLoading = _c.isLoading, error = _c.error, player = _c.data;
    var _d = __read(React.useState(0), 2), value = _d[0], setValue = _d[1];
    var _e = useListIntraSquadMatchesByPlayerId({ playerId: player === null || player === void 0 ? void 0 : player.id }), intraSquadMatchesIsLoading = _e.isLoading, intraSquadMatchesError = _e.error, intraSquadMatches = _e.data;
    if (error || intraSquadMatchesError) {
        return _jsx(ErrorView, { error: error || intraSquadMatchesError });
    }
    if (isLoading ||
        intraSquadMatchesIsLoading ||
        teamsIsLoading ||
        mutation.isLoading) {
        return _jsx(Loading, {});
    }
    if (!intraSquadMatches || !player || teamsError || !teams) {
        return _jsx(ErrorView, { error: teamsError });
    }
    var managedTeams = teams.filter(function (team) { var _a; return Number(team.createdBy) === ((_a = player.user) === null || _a === void 0 ? void 0 : _a.pk); });
    var playerTeams = teams.filter(function (team) { var _a; return Number(team.createdBy) !== ((_a = player.user) === null || _a === void 0 ? void 0 : _a.pk); });
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: mutation.isError, message: "Failed to delete player. Please try again." }), _jsx(DeleteDialog, { open: showDelete, onCancel: function () { return setShowDelete(false); }, title: "Delete ".concat(playerDisplayName(player), "?"), onConfirm: function () { return mutation.mutate(); }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 10, children: [_jsx(PlayerView, { player: player }), _jsx(Typography, { variant: "body2", fontStyle: "italic", mt: 1, children: player.bio })] }), _jsx(Grid, { item: true, xs: 2, children: _jsxs(Stack, { direction: "row", spacing: 1, children: [_jsx(IconButton, { "aria-label": "edit", children: _jsx(Link, { to: "/players/".concat(id, "/edit/"), children: _jsx(EditIcon, {}) }) }), !player.user && (_jsx(IconButton, { "aria-label": "delete", disabled: mutation.isLoading, children: _jsx(DeleteIcon, { onClick: function () {
                                            setShowDelete(true);
                                        } }) }))] }) })] }), _jsx(Divider, { sx: { margin: "20px" } }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 9, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: "divider" }, children: _jsxs(Tabs, { value: value, onChange: handleChange, "aria-label": "basic tabs example", children: [_jsx(Tab, __assign({ label: "Intra-Squad Match Stats" }, a11yProps(0))), _jsx(Tab, __assign({ label: "Teams" }, a11yProps(1)))] }) }), _jsx(TabPanel, { value: value, index: 0, children: _jsx(IntraSquadMatchStatsTable, { intraSquadMatches: intraSquadMatches, players: [player], singlePlayerView: true }) }), _jsxs(TabPanel, { value: value, index: 1, children: [teams.length === 0 && (_jsx(Typography, { variant: "body1", align: "center", children: "This player is not part of any team." })), _jsxs(Stack, { spacing: 2, children: [managedTeams.length > 0 && (_jsx(Typography, { variant: "subtitle1", children: "Manager" })), _jsx(Grid, { container: true, spacing: 2, children: managedTeams.map(function (team) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(TeamCard, { team: team }) }, team.id)); }) }), playerTeams.length > 0 && (_jsx(Typography, { variant: "subtitle1", children: "Player" })), _jsx(Grid, { container: true, spacing: 2, children: playerTeams.map(function (team) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(TeamCard, { team: team }) }, team.id)); }) })] })] })] }), _jsx(Grid, { item: true, xs: 3, children: _jsx(Stack, { children: _jsx(PlayerStatsCard, { player: player }) }) })] })] }));
};
