import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardContent, Divider, Grid, Typography, } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useRetrieveTeams } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { TeamsAvatarStack } from "./TeamsAvatarStack";
var Teams = function () {
    var _a = useRetrieveTeams(), isLoading = _a.isLoading, error = _a.error, teams = _a.data;
    if (isLoading) {
        return _jsx(Loading, {});
    }
    if (error || teams === undefined) {
        return (_jsx(ErrorView, { error: error || new Error("Failed to retrieve teams data.") }));
    }
    return (_jsxs(React.Fragment, { children: [_jsxs(Grid, { container: true, spacing: 2, alignItems: "center", children: [_jsx(Grid, { item: true, xs: 10, children: _jsx(Typography, { variant: "h4", children: _jsx("strong", { children: "Teams" }) }) }), _jsx(Grid, { item: true, xs: 2, children: _jsx(Link, { to: "/teams/create/", children: _jsx(Button, { variant: "contained", color: "primary", fullWidth: true, children: "Create Team" }) }) })] }), _jsx(Divider, { sx: { my: 2 } }), teams.length > 0 ? (_jsx(TeamsAvatarStack, { teams: teams })) : (_jsx(Card, { sx: { minWidth: 275, mt: 2 }, children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h5", component: "div", children: "No teams found!" }), _jsx(Typography, { variant: "body2", sx: { mt: 1 }, children: "You're currently not part of any teams. Why not create one now?" })] }) }))] }));
};
export default Teams;
