import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
function Copyright() {
    return (_jsxs(Typography, { variant: "body2", color: "text.secondary", align: "center", children: ["© ", _jsx(Link, { color: "inherit", href: "/", children: "log.soccer" }), " ", new Date().getFullYear(), "."] }));
}
export default function Footer() {
    return (_jsx(Box, { component: "footer", sx: {
            py: 3,
            px: 2,
            mt: "auto",
            backgroundColor: function (theme) {
                return theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800];
            },
            position: "relative",
            bottom: 0,
            width: "100%",
        }, children: _jsxs(Container, { maxWidth: "lg", children: [_jsxs(Grid, { container: true, spacing: 4, justifyContent: "center", children: [_jsxs(Grid, { item: true, xs: 12, sm: 3, children: [_jsx(Typography, { variant: "h6", gutterBottom: true, children: "Company" }), _jsx(Link, { href: "/about-us", variant: "body2", display: "block", gutterBottom: true, children: "About Us" })] }), _jsxs(Grid, { item: true, xs: 12, sm: 3, children: [_jsx(Typography, { variant: "h6", gutterBottom: true, children: "Legal" }), _jsx(Link, { href: "/privacy-policy", variant: "body2", display: "block", gutterBottom: true, children: "Privacy Policy" }), _jsx(Link, { href: "/terms-of-service", variant: "body2", display: "block", gutterBottom: true, children: "Terms of Service" })] }), _jsxs(Grid, { item: true, xs: 12, sm: 3, children: [_jsx(Typography, { variant: "h6", gutterBottom: true, children: "Tools" }), _jsx(Link, { href: "/api/", variant: "body2", display: "block", gutterBottom: true, children: "API" })] })] }), _jsx(Box, { mt: 3, children: _jsx(Copyright, {}) })] }) }));
}
