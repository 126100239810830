var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ErrorView } from "../nav/Error";
import { CreateExternalUserAndAddToTeamForm } from "./CreateExternalUserAndAddToTeamForm";
export var PlayerAddOption;
(function (PlayerAddOption) {
    PlayerAddOption["CreatePlayer"] = "createPlayer";
    PlayerAddOption["AddExistingPlayers"] = "addExistingPlayers";
})(PlayerAddOption || (PlayerAddOption = {}));
export var AddPlayerToTeam = function () {
    var _a = __read(useState(null), 2), option = _a[0], setOption = _a[1];
    if (option === null) {
        return _jsx(PlayerAddOptionPicker, { setOption: setOption });
    }
    if (option === PlayerAddOption.CreatePlayer) {
        return _jsx(CreateExternalUserAndAddToTeamForm, {});
    }
    return _jsx(ErrorView, { error: new Error("Must select an option!") });
};
export var PlayerAddOptionPicker = function (_a) {
    var setOption = _a.setOption;
    var handleSelectOption = function (option) {
        setOption(option);
    };
    return (_jsxs(Grid, { container: true, spacing: 2, justifyContent: "center", children: [_jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Card, { onClick: function () { return handleSelectOption(PlayerAddOption.CreatePlayer); }, sx: {
                        border: "1px solid #ccc",
                        cursor: "pointer",
                        textAlign: "center",
                        padding: "20px",
                    }, children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h5", component: "div", children: "Create a New Player" }), _jsx(Typography, { variant: "body2", color: "text.secondary", children: "Create a new player and add them to the team." }), _jsx(Button, { variant: "outlined", sx: { marginTop: "15px" }, children: "Select" })] }) }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Card, { onClick: function () { return handleSelectOption(PlayerAddOption.AddExistingPlayers); }, sx: {
                        border: "1px solid #ccc",
                        cursor: "pointer",
                        textAlign: "center",
                        padding: "20px",
                    }, children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h5", component: "div", children: "Add Existing Player(s)" }), _jsx(Typography, { variant: "body2", color: "text.secondary", children: "Add existing players from your other teams." }), _jsx(Button, { variant: "outlined", sx: { marginTop: "15px" }, children: "Select" })] }) }) })] }));
};
