import { playerDisplayName } from "./PlayerDisplayNameLink";
export var sortPlayers = function (a, b) {
    if (!a) {
        return -1;
    }
    if (!b) {
        return 1;
    }
    return playerDisplayName(a) < playerDisplayName(b) ? -1 : 1;
};
export var calculateAge = function (dob) {
    var birthDate = new Date(dob);
    var today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var monthDiff = today.getMonth() - birthDate.getMonth();
    // If the current month is before the birth month, or it's the same month but the current day is before the birth day, subtract 1 from age.
    if (monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};
